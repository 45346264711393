<template>
    <div class="edit-navigator-text-container">
        <div class="navigator-sticker-content">
            <div class="sticker-list-container" v-if="!showAllStickers">
                <div class="sticker-item" v-for="item in stickers" :key="item.id">
                    <div class="sticker-container-info">
                        <span>{{ item.label }}</span>
                        <div class="sticker-btn-container" @click="ViewAllStickers(item.name)">
                            <span>全部</span>
                            <el-icon class="point-right-icon"><ArrowRight /></el-icon>
                        </div>
                    </div>
                    <div class="sticker-container-content">
                        <div 
                            class="sticker" 
                            v-for="sticker in item.value" 
                            :key="sticker.id" 
                            @click="createStciker(sticker.url)"
                            >
                            <img :src="sticker.url" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="sticker-list-container" v-if="showAllStickers">
                <div class="sticker-goBack-btn">
                    <el-icon 
                        style="height: 20px;width: 20px;font-size: large;font-weight: bold;cursor: pointer;" 
                        @click="colseShowStickersList"
                        ><Back/>
                    </el-icon>
                    <span>{{ viewAllStickersList.label }}</span>
                </div>
                <div class="show-stickers-list">
                    <div 
                        v-for="item in viewAllStickersList.value" 
                        :key="item.id" 
                        @click="createStciker(item.url)">
                        <img :src="item.url" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { ArrowRight,Back } from '@element-plus/icons';
  import { createStcikerElement } from '@/utils/commonFunctions';
  export default {
      name: "navigator-sticker",
      components:{
        ArrowRight,
        Back
      },

      data() {
          return {
            // 所有的贴纸信息
            stickers:[
                { 
                    id:1, 
                    name:"DiscountInformations",
                    label:"折扣信息",
                    value: [
                        { id: 'DiscountInformation-sticker-1', url: require("../../assets/sticker/DiscountInformation/d45b78adb689426983e923fcf970bf48.png") },
                        { id: 'DiscountInformation-sticker-2', url: require("../../assets/sticker/DiscountInformation/dd46b116871c499586cc3ba38055fa19.png") },
                        { id: 'DiscountInformation-sticker-3', url: require("../../assets/sticker/DiscountInformation/e1854d23b54e4b27a06f539957dc4252.png") },
                        { id: 'DiscountInformation-sticker-4', url: require("../../assets/sticker/DiscountInformation/f21fc1a420184f44822b62354644e29f.png") },
                    ]
                },
                { 
                    id:2, 
                    name:"NewProductsLaunched",
                    label:"新品上线",
                    value: [
                        { id: 'NewProductsLaunched-sticker-1', url: require("../../assets/sticker/NewProductsLaunched/76dba68d284c444aad47617dc335d1f2.png") },
                        { id: 'NewProductsLaunched-sticker-2', url: require("../../assets/sticker/NewProductsLaunched/582e476feb6249c3bd415a37b732a315.png") },
                        { id: 'NewProductsLaunched-sticker-3', url: require("../../assets/sticker/NewProductsLaunched/078840ed9c7844298fbad817c6e7e412.png") },
                        { id: 'NewProductsLaunched-sticker-4', url: require("../../assets/sticker/NewProductsLaunched/e5f2411fd4e04c99beae40a0bcbb02e9.png") },
                    ]
                },
                { 
                    id:3, 
                    name:"HotSaleProducts",
                    label:"热卖商品",
                    value: [
                        { id: 'HotSaleProduct-sticker-1', url: require("../../assets/sticker/HotSaleProducts/0b26f66638e146af91b6b7c86b875104.png") },
                        { id: 'HotSaleProduct-sticker-2', url: require("../../assets/sticker/HotSaleProducts/02b950c68c45401989e08de1f8e024c6.png") },
                        { id: 'HotSaleProduct-sticker-3', url: require("../../assets/sticker/HotSaleProducts/03ad8e026dd241b4afcd2fa56412ee46.png") },
                        { id: 'HotSaleProduct-sticker-4', url: require("../../assets/sticker/HotSaleProducts/d5377a8c584b4ca6935a8bd0356ec7c8.png") },
                    ]
                },
                { 
                    id:4, 
                    name:"OrderNow",
                    label:"立即下单",
                    value: [
                        { id: 'OrderNow-sticker-1', url: require("../../assets/sticker/OrderNow/7fe259ecc89d4a25a0137664dbb04377.png") },
                        { id: 'OrderNow-sticker-2', url: require("../../assets/sticker/OrderNow/8bda006eba0e49d79a35ffddf3be0cfd.png") },
                        { id: 'OrderNow-sticker-3', url: require("../../assets/sticker/OrderNow/a44220513c2948039d2faa4d6f087820.png") },
                        { id: 'OrderNow-sticker-4', url: require("../../assets/sticker/OrderNow/ecf7819827e84feea086322af8817a39.png") },
                    ]
                }
            ],
            // 查看某一个分类的所有贴纸
            viewAllStickersList:[],
            showAllStickers:false,
          }
      },
    methods:{
        // 查看某一个贴纸分类
        ViewAllStickers(sticker){
            this.showAllStickers = true
            // 筛选分类
            this.viewAllStickersList = this.stickers.filter(item => item.name == sticker)
            this.viewAllStickersList = this.viewAllStickersList[0]
        },
        // 返回
        colseShowStickersList(){
            this.showAllStickers = false
        },
        // 在透明区域创建贴纸元素传入贴纸的url
        createStciker(imageUrl){
            console.log(imageUrl)
            createStcikerElement(imageUrl,"themeContainer")
        },

    },
  };
  </script>
  
<style>

  
</style>